var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "container relative height-100p"
  }, [_c('CommissionList', {
    attrs: {
      "counts": 50,
      "ltview": 'list'
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };